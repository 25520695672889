<template>
  <div>
    <b-button
      size="sm"
      variant="secondary"
      class="my-2 btn-action "
      style="border-style: none;"
      @click="hasHistory() ? $router.go(-1) : $router.push('/')"
    >
      <feather-icon icon="ArrowLeftIcon" size="25" />
    </b-button>
    <b-card :title="$i18n.messages.Pedido">
      <b-row>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.EstadoPedido }}:</strong>
            <b-badge pill :variant="colorPedido(order.estado)" class="ml-1">
              {{ statusPedido(order.estado) }}
            </b-badge>
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Fecha }}:</strong>
            {{ formatDate(order.fecha) }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.totalCajas }}:</strong>
            {{ order.totalCajas }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.CajasPalet }}:</strong>
            {{ order.totalCajasPalet }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Cantidad }}:</strong>
            {{
              Number(parseFloat(order.totalM2)).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              }) + " m2"
            }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.totalWeight }}:</strong>
            {{
              Number(parseFloat(order.totalPeso)).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              }) + " kg"
            }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Client }}:</strong>
            {{ order.nameCliente }}
          </p>
        </b-col>
        <b-col cols="12" lg="6">
          <p>
            <strong>{{ $i18n.messages.Picking }}: </strong>
            <b-badge
              pill
              :variant="order.totalPicking ? 'danger' : 'light'"
              class="ml-1"
            >
              <span v-if="order.totalPicking">{{
                $i18n.messages.ConRecargoPicking
              }}</span>
              <span v-else>{{ $i18n.messages.SinRecargoPicking }}</span>
            </b-badge>
          </p>
        </b-col>
        <b-col cols="12" lg="6" v-if="fechaFormatted != ''">
          <p>
            <strong>{{ $i18n.messages.FechaCarga }}:</strong>
            {{ fechaFormatted }}
          </p>
        </b-col>
        <b-col cols="12" lg="12" v-if="order.observaciones">
          <p>
            <strong>{{ $i18n.messages.Observaciones }}:</strong>
            {{ order.observaciones }}
          </p>
        </b-col>

        <b-col cols="12" lg="12" class="mt-3" v-if="hasLineas">
          <b-table responsive="sm" :items="order.lineas" :fields="fields">
            <template #cell(picking)="data">
              <b-badge pill :variant="data.item.picking ? 'danger' : 'light'">
                <span v-if="data.item.picking">{{
                  $i18n.messages.ConRecargoPicking
                }}</span>
                <span v-else>{{ $i18n.messages.SinRecargoPicking }}</span>
              </b-badge>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BBadge, BButton } from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { config } from "../shared/app.config";
import moment from "moment";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BTable,
    BButton,
    BBadge,
  },
  data() {
    return {
      modal: true,
      fechaFormatted: "",
      fields: [
        {
          key: "articulo_nombre",
          label: this.$i18n.messages.Articulo,
        },
        {
          key: "cantidad",
          label: this.$i18n.messages.Cantidad,
          formatter(value, key, item) {
            const cantidad = parseFloat(value).toFixed(4);
            return (
              Number(parseFloat(cantidad)).toLocaleString("de-DE", {
                maximumFractionDigits: 4,
              }) +
              " " +
              item.unidades
            );
          },
        },
        {
          key: "picking",
          label: this.$i18n.messages.Picking,
        },
        {
          key: "totalCajas",
          label: this.$i18n.messages.totalCajas,
        },
        {
          key: "MetrosCaja",
          label: this.$i18n.messages.PorCajaM2,
          formatter(value) {
            return Number(parseFloat(value)).toLocaleString("de-DE", {
              maximumFractionDigits: 4,
            });
          },
        },
        {
          key: "totalCajasPalet",
          label: this.$i18n.messages.Pales,
          formatter(value, key, item) {
            return Math.floor(item.totalCajas / item.totalCajasPalet);
          },
        },
        {
          key: "total_pesoM2",
          label: this.$i18n.messages.weight,
          formatter(value) {
            return Number(parseFloat(value)).toLocaleString("de-DE", {
              maximumFractionDigits: 4,
            });
          },
        },
      ],
    };
  },
  created() {
    this.setLoading(true);
    this.getOrder(this.$route.params.id).then(() => {
      if (this.order.fechaCarga != "undefined") {
        var momentObj = moment(this.order.fechaCarga, "YYYYMMDD");
        var momentString = momentObj.format("DD-MM-YYYY");
        this.fechaFormatted = momentString;
      }

      this.setLoading(false);
    });
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
    }),
    hasLineas() {
      if (this.order.lineas) {
        return this.order.lineas.length;
      } else {
        return false;
      }
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    ...mapActions({
      getOrder: "orders/getOrder",
    }),
    ...mapMutations({
      setLoading: "app/setLoading",
    }),
    formatDate(date) {
      const d = new Date(date);

      var minutos = d.getMinutes().toString();
      if (minutos.length == 1) {
        minutos = "0" + minutos;
      }

      const datestring = `${d.getDate()}/${d.getMonth() +
        1}/${d.getFullYear()} ${d.getHours()}:${minutos}`;

      return datestring;
    },
    colorPedido(val) {
      return config.colorPedidos[val];
    },
    statusPedido(val) {
      return config.estadosPedidos[val];
    },
  },
};
</script>
